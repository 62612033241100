import React from 'react';

import Footer from 'components/footer';
import Head from 'components/head';
import Nav from 'components/nav';

const DefaultLayout = ({
  children,
  lang = 'pl',
  metaDescription,
  withFooter = true,
  title,
  url,
}) => (
  <>
    <Head
      lang={lang}
      metaDescription={metaDescription}
      pageTitle={title}
      url={url}
    />
    <Nav lang={lang} />
    {children}
    {withFooter && <Footer />}
  </>
);

export default DefaultLayout;
