import React from 'react';
import Img from 'gatsby-image';
import cx from 'classnames';

const Header = ({ date, image, title, withBorder = false }) => (
  <header className={cx('hero', withBorder && 'hero--border')}>
    {image && (
      <Img
        className="hero-image u-image"
        alt={`${title} illustration`}
        fluid={image}
      />
    )}
    <div className="hero-heading">
      <h1 className="hero-title">{title}</h1>
      {date && <time className="hero-date">{date}</time>}
    </div>
  </header>
);

export default Header;
