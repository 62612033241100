import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

const links = [
  { name: 'O nas', href: '/o-nas' },
  { name: 'Kontakt', href: '/kontakt' },
];

const linksEn = [
  { name: 'About', href: '/en/about' },
  { name: 'Contact', href: '/en/contact' },
];

const Nav = ({ lang, withBorder = false }) => {
  const navLinks = lang === 'pl' ? links : linksEn;

  return (
    <nav className={cx('nav', withBorder && 'nav--border')}>
      <Link className="nav-logo" to={lang === 'pl' ? '/' : '/en'}>
        AwesomeLogo
      </Link>
      <ul className="nav-links">
        {navLinks.map((link, index) => (
          <li
            className={cx(
              'nav-links-item',
              index > 0 && 'nav-links-item--margin'
            )}
            key={link.name}
          >
            <Link className="nav-link" to={link.href}>
              {link.name}
            </Link>
          </li>
        ))}
        <li className="nav-links-item nav-links-item--margin nav-links-item--lang">
          <Link className="nav-link nav-link--lang" to="/">
            PL
          </Link>
        </li>
        <li className="nav-links-item">
          <Link className="nav-link nav-link" to="/en">
            EN
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
