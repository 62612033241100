import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p className="footer-text">
      © Nika Zawila – {new Date().getFullYear()}
    </p>
  </footer>
);

export default Footer;
